###
#
# @file signature
#
###

SignaturePad = require('signature_pad').default

class Signature

  constructor: (@app, config) ->
    ##
    # DOM Elements
    # @type {string}
    ##
    @el =
      body:                 'body'
      button_save_sign:     '[data-save-signature]'
      button_clear_sign:    '[data-clear-signature]'
      button_change_sign:   '[data-change-signature]'
      container_signature:  '.signature-container'

    @app.register_turbolinks_load_handler @init

    $(document).on 'click', '[data-clear-signature]', @clear_pad
    $(document).on 'click', '[data-save-signature]', @save_pad
    $(document).on 'click', '[data-change-signature]', @change_pad

  init: =>
    unless $('body.doctors-profiles.page-complete_registration').length || $('body.doctors-profiles.page-index').length
      return
    @canvas = document.getElementById('signature_pad')
    @container = document.getElementById('signature-draw')
    @signature_pad = new SignaturePad @canvas
    @signature_pad.penColor = "rgb(0, 77, 255)"

    if @canvas
      setTimeout (=>
        @canvas.height = @container.offsetHeight - 40
        @canvas.width = @container.offsetWidth - 40
        return
      ), 2000

  clear_pad: =>
    if $("#{@el.container_signature} .signature-preview").length > 0
      $("#{@el.container_signature} .signature-preview").remove()
    @signature_pad.clear()


  change_pad: =>
    @signature_pad.clear()
    $(@el.container_signature).toggleClass 'mode-preview'
    $(@el.button_save_sign).removeClass 'disabled'
    $(@el.button_save_sign).text 'Guardar firma'
    $(@el.button_clear_sign).removeClass 'hide'
    $(@el.button_change_sign).addClass 'hide'
    $('.btn-next').addClass 'disabled'

  save_pad: (e)=>
    if @signature_pad.isEmpty()
      alert 'No ha dibujado ninguna firma aún'
      e.preventDefault()
    else
      $('.signature_pad_input').val @signature_pad.toDataURL()
      $(@el.container_signature).toggleClass 'mode-preview'
      $(@el.button_save_sign).addClass 'disabled'
      $(@el.button_save_sign).text 'Firma guardada'
      $(@el.button_clear_sign).addClass 'hide'
      $(@el.button_change_sign).removeClass 'hide'
      $('.btn-next').removeClass 'disabled'

  resizeCanvas: (canvas)=>
    ratio =  Math.max(window.devicePixelRatio || 1, 1)
    canvas.width = canvas.offsetWidth * ratio
    canvas.height = canvas.offsetHeight * ratio
    canvas.getContext("2d").scale(ratio, ratio)


module.exports = Signature
