###
#
# @file application
#
###

Lightbox = require('lightbox2/dist/js/lightbox.js')

class ApplicationDoctor

  constructor: (@app, config) ->

    ##
    # DOM Elements
    # @type {string}
    ##
    @el =
      body:                   'body'
      application_form:       '.application-doctor-review'
      action_button:          '[data-application-action]'
      action_input:           '[name="application[action]"]'

    @app.register_turbolinks_load_handler @init

    $(document).on 'click', @el.action_button, @application_action
    $(document).on 'change', '#doctor_notes', @doctor_notes_clear

    $(document).on 'click', '[data-referals-add-page]', @add_referal_page
    $(document).on 'click', '[data-referals-remove-page]', @remove_referal_page

    $(document).on 'click', '[data-xrays-add-page]', @add_xrays_page
    $(document).on 'click', '[data-xrays-remove-page]', @remove_xrays_page

    $(document).on 'click', '[data-medicines-add-page]', @add_medicines_page
    $(document).on 'click', '[data-medicines-remove-page]', @remove_medicines_page

    $(document).on 'click', '[data-labs-add-page]', @add_labs_page
    $(document).on 'click', '[data-labs-remove-page]', @remove_labs_page

    $(document).on 'click', '[data-prefill-value]', @prefill_labs

    $(document).on 'click', '[data-telemedicine-link]', @telemedicine_link
    $(document).on 'click', '[data-start-videocall]', @telemedicine_open_session

    $(document).on 'focus', 'input[name="application_number"]', @clean_application_type
    $(document).on 'focus', 'select[name="application_type"]', @clean_application_number


  init: =>
    unless $(@el.body).hasClass 'doctors-applications'
      return

    @notes_container = $("#doctor_notes_container")
    @notes_error = @notes_container.find('.form-error')

    Lightbox.init()

  
  telemedicine_open_session: (e)=>
    url = $(e.currentTarget).data 'start-videocall'
    options  = "width=#{screen.width},height=#{screen.height},top=0,left=0,fullscreen=yes,directories=no,location=no, menubar=no,resizable=no,scrollbars=no,status=no,toolbar=no";

    $("#telemedicine-fields-manual").remove()
    $("#telemedicine-fields-automatic").css('display', 'block')

    # Add the session_start date for telemedicine
    payload = {
      hash: $('#application_hash').val()
    }
    token = $('meta[name=csrf-token]').attr('content')

    new Promise (resolve, reject)=>
      payload = JSON.parse JSON.stringify payload
      $.ajax
        url: "/dashboard/telemedicine/session_start"
        type: "POST"
        headers:
          'X-CSRF-Token': token #Add the token manually
        data: payload
        dataType: 'json'
        success: (payload)=>
          window.open(url, "_blank", options)
          resolve()
        error: @push_failure.bind this, reject

  
  telemedicine_link: (e)=>
    
    # Copy telemedicine link and show videocall button
    e.preventDefault()
    link = $(e.currentTarget).data 'telemedicine-link'
    msg = "Ingresa a la siguiente dirección por favor: #{link}. Probablemente te pida iniciar sesión en ServiDocPR.com antes de continuar, como medida adicional de seguridad."
    await navigator.clipboard.writeText(msg)
    $("[data-telemedicine-link] span").text "Copiado!"
    $("[data-start-videocall]").css "display", "inline-block"


  push_failure: (reject, jqXHR)=>
    console.log jqXHR
    reject('There was an unexpected error')


  clean_application_type: (e)=> 
    e.preventDefault()
    $('select[name="application_type"]').val("")

  clean_application_number: (e)=> 
    e.preventDefault()
    $('input[name="application_number"]').val("")


  application_action: (e)=>
    e.preventDefault()
    form_type = $('input[name="application[type]"]').val()

    if form_type == "certificado_medico"
      return false unless @validate_certificado_medico()

    action = $(e.currentTarget).data 'application-action'
    $(@el.action_input).val action

    if action == "denied" || action == "errors"
      if $("#doctor_notes").val() == ""
        @notes_container.addClass 'form-field--has-error'
        @notes_error.show()
        return false
      else
        $(@el.application_form).submit()
    else
      $(@el.application_form).submit()


  validate_certificado_medico: ()=>
    if $('input[value="Otros (utilice el siguiente campo para dar más detalles)"]').is(':checked') && $('#diagnostico').val() == ""
      alert("Debe ingresar un Diagnóstico")
      $('#diagnostico').focus()
      return false

    if $('input[value="Otros (utilice el siguiente campo para dar más detalles)"]').is(':checked') && $('#codigo_dx').val() == ""
      alert("Debe ingresar un código Dx")
      $('#codigo_dx').focus()
      return false

    return true


  doctor_notes_clear: (e)=>
    @notes_container.removeClass 'form-field--has-error'
    @notes_error.hide()


  # REFERALS
  add_referal_page: (e)=>
    e.preventDefault()
    @referal_template().appendTo('#referals_pages')

  remove_referal_page: (e)=>
    e.preventDefault()
    $(e.currentTarget).parents('.custom-prescription-content').remove()

  referal_template: ()=>
    $("<div class='custom-prescription-content'>
        <label class='form-field__label-only'>Contenido Hoja/Orden:</label>
        <div class='form-field'>
          <div class='form-field__control form-field__control--75 form-field__control--mb-20'>
            <input name='medical_consultation[prescriptions_referrals][][content]' required=' type='text' class='form-field__input' autocomplete='off' autocorrect='off' spellcheck='false' role='presentation' placeholder='Referido aquí...'>
          </div>
          <div class='form-field__control form-field__control--75'>
            <input name='medical_consultation[prescriptions_referrals][][diagnosis]' required=' type='text' class='form-field__input' autocomplete='off' autocorrect='off' spellcheck='false' role='presentation' placeholder='Diagnóstico aquí (opcional)...'>
          </div>
          <a href='#' data-referals-remove-page class='button delete small borrar'>Borrar</a>
        </div>
      </div>")

  # XRAYS
  add_xrays_page: (e)=>
    e.preventDefault()
    @xrays_template().appendTo('#xrays_pages')

  remove_xrays_page: (e)=>
    e.preventDefault()
    $(e.currentTarget).parents('.custom-prescription-content').remove()

  xrays_template: ()=>
    $("<div class='custom-prescription-content'>
        <label class='form-field__label-only'>Contenido Hoja/Orden:</label>
        <div class='form-field'>
          <div class='form-field__control form-field__control--75 form-field__control--mb-20'>
            <input name='medical_consultation[prescriptions_x_rays][][content]' required=' type='text' class='form-field__input' autocomplete='off' autocorrect='off' spellcheck='false' role='presentation' placeholder='Rayos-X aquí...'>
          </div>
          <div class='form-field__control form-field__control--75'>
            <input name='medical_consultation[prescriptions_x_rays][][diagnosis]' required=' type='text' class='form-field__input' autocomplete='off' autocorrect='off' spellcheck='false' role='presentation' placeholder='Diagnóstico aquí (opcional)...'>
          </div>
          <a href='#' data-xrays-remove-page class='button delete small borrar'>Borrar</a>
        </div>
      </div>")

  # MEDICINES
  add_medicines_page: (e)=>
    e.preventDefault()
    @medicines_template().appendTo('#medicines_pages')

  remove_medicines_page: (e)=>
    e.preventDefault()
    $(e.currentTarget).parents('.custom-prescription-content').remove()

  medicines_template: ()=>
    id_refill = Math.floor(Math.random() * Date.now())
    id_1 = Math.floor(Math.random() * Date.now())
    id_no_sustituir = Math.floor(Math.random() * Date.now())

    $("<div class='custom-prescription-content'>
        <label class='form-field__label-only'>Contenido Hoja/Orden:</label>
        <div class='form-field'>
          <div class='form-field__control form-field__control--textarea form-field__control--75 form-field__control--mb-20'>
            <textarea name='medical_consultation[prescriptions_medicines][][content]' class='form-field__textarea' placeholder='Medicamentos aquí...'></textarea>
          </div>
          <div class='form-field__control form-field__control--75'>
            <input name='medical_consultation[prescriptions_medicines][][diagnosis]' required=' type='text' class='form-field__input' autocomplete='off' autocorrect='off' spellcheck='false' role='presentation' placeholder='Diagnóstico aquí (opcional)...'>
          </div>
          <a href='#' data-medicines-remove-page class='button delete small borrar'>Borrar</a>
        </div>
        <label class='form-field__label-checkbox'>Opciones receta</label>
        <div class='custom-checkbox inline'>
          <input type='checkbox' name='medical_consultation[prescriptions_medicines][][options][]' value='Refill' id='#{id_refill}'>
          <label for='#{id_refill}'>Refill</label>
        </div>
        <div class='custom-checkbox inline'>
          <input type='checkbox' name='medical_consultation[prescriptions_medicines][][options][]' value='1' id='#{id_1}'>
          <label for='#{id_1}'>1</label>
        </div>
        <div class='custom-checkbox inline'>
          <input type='checkbox' name='medical_consultation[prescriptions_medicines][][options][]' value='No Sustituir' id='#{id_no_sustituir}'>
          <label for='#{id_no_sustituir}'>No Sustituir</label>
        </div>
      </div>")


  # LABS
  add_labs_page: (e)=>
    e.preventDefault()
    @labs_template().appendTo('#labs_pages')

  remove_labs_page: (e)=>
    e.preventDefault()
    $(e.currentTarget).parents('.custom-prescription-content').remove()

  labs_template: ()=>
    $("<div class='custom-prescription-content'>
        <label class='form-field__label-only'>Contenido Hoja/Orden:</label>
        <div class='form-field'>
          <div class='form-field__control form-field__control--textarea form-field__control--75 form-field__control--mb-20'>
            <textarea name='medical_consultation[prescriptions_labs][][content]' class='form-field__textarea' placeholder='Laboratorios aquí...'></textarea>
          </div>
          <div class='form-field__control form-field__control--75'>
            <input name='medical_consultation[prescriptions_labs][][diagnosis]' required=' type='text' class='form-field__input' autocomplete='off' autocorrect='off' spellcheck='false' role='presentation' placeholder='Diagnóstico aquí (opcional)...'>
          </div>
          <a href='#' data-labs-remove-page class='button delete small borrar'>Borrar</a>
        </div>
        <label class='form-field__label-only'>Autocompletar con:</label>
        <div class='row'>
          <div class='small-12 medium-6 columns'>
            <a data-prefill-value='Blood Type and Group - Dx: D64.9'>+ Blood Type and Group</a>
            <a data-prefill-value='BMP - Dx: R73.01 R73.09'>+ BMP</a>
            <a data-prefill-value='CBC +Diff - Dx: D64.9'>+ CBC +Diff</a>
            <a data-prefill-value='Clamidia - Dx: Z11.3'>+ Clamidia</a>
            <a data-prefill-value='CMP - Dx: R73.01 R73.09 I10'>+ CMP</a>
            <a data-prefill-value='Covid19 PCR (molecular) - Dx: Z20.822 / Z11.52 / U07.1'>+ Covid19 PCR (molecular)</a>
            <a data-prefill-value='Covid19 Antígenos - Dx: Z20.822 / Z11.52 / U07.1'>+ Covid19 Antígenos</a>
            <a data-prefill-value='Drug screening 3 - Dx: Z02.83'>+ Drug screening 3</a>
            <a data-prefill-value='Free T4 - Dx: E03.9'>+ Free T4</a>
            <a data-prefill-value='FSH, LH, Estrógeno, Progesterona y Testosterona - Dx: N95.1'>+ FSH, LH, Estrógeno, Progesterona y Testosterona</a>
            <a data-prefill-value='Gonorrea - Dx: Z11.3'>+ Gonorrea</a>
            <a data-prefill-value='H. Pyroli Blood Test IgG - Dx: K29.00'>+ H. Pyroli Blood Test IgG</a>
            <a data-prefill-value='H. Pyroli Stool Antigen Test - Dx: K29.00'>+ H. Pyroli Stool Antigen Test</a>
            <a data-prefill-value='HCG cuantitativo - Dx: Z32.00'>+ HCG cuantitativo</a>
            <a data-prefill-value='Hepatitis B cuantification - Dx: Z01.84'>+ Hepatitis B cuantification</a>
            <a data-prefill-value='Hepatitis Bs Antibody Cuantitativo - Dx: Z01.84'>+ Hepatitis Bs Antibody Cuantitativo</a>
            <a data-prefill-value='Herpes 1-2 IgM - Dx: Z11.3'>+ Herpes 1-2 IgM</a>
            <a data-prefill-value='HgbA1C - Dx: R73.01 R73.09'>+ HgbA1C</a>
          </div>
          <div class='small-12 medium-6 columns'>
            <a data-prefill-value='HIV - Dx: Z11.3'>+ HIV</a>
            <a data-prefill-value='Inmunoglobulina E (IgE) - Dx: T78.40XA'>+ Inmunoglobulina E (IgE)</a>
            <a data-prefill-value='Influenza A & B (Rapid Test) - Dx: J11.1'>+ Influenza A & B (Rapid Test)</a>
            <a data-prefill-value='Lipid panel - Dx: E78.2 E78.5'>+ Lipid panel</a>
            <a data-prefill-value='Microalbuminuria - Dx: I10'>+ Microalbuminuria</a>
            <a data-prefill-value='Mycoplasma (IgG. IgM) - Dx:  A49.3'>+ Mycoplasma (IgG. IgM)</a>
            <a data-prefill-value='Occult blood - Dx: Z11.12'>+ Occult blood</a>
            <a data-prefill-value='Orina de 24 horas/Proteínas - Dx: N18.1'>+ Orina de 24 horas/Proteínas</a>
            <a data-prefill-value='PSA - Dx: N40.0'>+ PSA</a>
            <a data-prefill-value='PTH - Dx: E03.9'>+ PTH</a>
            <a data-prefill-value='T3 - Dx: E03.9'>+ T3</a>
            <a data-prefill-value='Testosterone level - Dx: N95.1, N50.3'>+ Testosterone level</a>
            <a data-prefill-value='Título de Varicela - Dx: Z01.84'>+ Título de Varicela</a>
            <a data-prefill-value='TSH - Dx: E03.9'>+ TSH</a>
            <a data-prefill-value='U/A - Dx: N39.0'>+ U/A</a>
            <a data-prefill-value='U/C - Dx: N39.0'>+ U/C</a>
            <a data-prefill-value='V.D.R.L. - Dx: Z11.3'>+ V.D.R.L.</a>
          </div>
        </div>
      </div>")

  prefill_labs: (e)=>
    e.preventDefault()
    $(e.currentTarget).addClass "selected-lab"
    selected_lab = $(e.currentTarget).data 'prefill-value'
    $labs_textarea = $(e.currentTarget).parents('.custom-prescription-content').find('textarea')
    current_labs = $labs_textarea.val()
    new_labs = "#{current_labs} #{selected_lab}\r\n"
    $labs_textarea.val new_labs


module.exports = ApplicationDoctor
